import React from 'react';
import { Card } from 'react-bootstrap';
import '../App.css'

function GameList({ games }) {

  const _games = games;

  return (
    <>
      {_games.map((game) => (
        // dark card teme
          <Card >
            <Card.Body style={{backGround : "black", color :"#FFFFFF"}} >
              <Card.Title style={{color :"var(--bs-primary)"}}>{game.name}</Card.Title>
              <Card.Text>
                <strong>{game.admin ? 'Vous êtes administateur de cette partie' : ''} </strong> <br />
                <strong>{game.started ? 'La partie a commencé' : 'La partie n\'a pas démarrée'}</strong>  <br />
                <strong>{game.archived ? 'La partie est archivé' : ''}</strong> 
                
              </Card.Text>
            </Card.Body>
          </Card>
      ))}
      
    </>
  );
}

export default GameList;

//{game.admin && !game.started ? <Button variant="primary" href={`/game/${game.id}`}>Demarer la partie</Button>: ''}