import logo from '../logo.svg';
import '../App.css';

function Admin() {
    return (
        <div className="Admin">
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
            Edit <code>src/Pages/Admin.js</code> and save to reload.
        </p>
        <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
        >
            Learn React
        </a>
        </header>
        </div>
    )
}

export default Admin;