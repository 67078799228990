import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { checkAuthAPI } from '../Services/serviceApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NavKiller() {
    const [auth,setAuth] = useState(false);
    const currentUrl = window.location.href;
    const currentUrlArray = currentUrl.split('/');
    const nav = useNavigate();

    useEffect(()=>{
        checkAuthAPI().then(connected => {
            if(connected){
                setAuth(true);
            } else {
                setAuth(false);
            }
        })
    },[nav]);

    if(auth){
            return (
                <Navbar expand="lg" className="navbar-dark bg-dark">
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <h1 style={{ paddingLeft: "10px", margin: 0 }}>
                                <span style={{ color: 'white', fontSize: '40px', textAlign: 'center' }}>KILL</span>
                                <span style={{ color: 'var(--bs-primary)', fontSize: '40px', textAlign: 'center' }}>ER</span>
                            </h1>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto" >
                                <Nav.Link href="/" active={currentUrlArray[3] === '' || currentUrlArray[3] === 'home' ? true : false} >
                                    Accueil
                                </Nav.Link>
                                <Nav.Link href="/profil" active={currentUrlArray[3] === 'profil' && (currentUrlArray.length === 4 ) ? true : false} >
                                    Profil
                                </Nav.Link>
                                <Nav.Link href="/logout" active={currentUrlArray[3] === 'login' && (currentUrlArray.length === 4 ) ? true : false}>
                                    Déconnexion
                                </Nav.Link>
         
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
        
            );
        
    }else
    {return (
        <Navbar expand="lg" className="navbar-dark bg-dark">
            <Container fluid>
                <Navbar.Brand href="/">
                    <h1 style={{ paddingLeft: "10px", margin: 0 }}>
                        <span style={{ color: 'white', fontSize: '40px', textAlign: 'center' }}>KILL</span>
                        <span style={{ color: 'var(--bs-primary)', fontSize: '40px', textAlign: 'center' }}>ER</span>
                    </h1>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" >
                        <Nav.Link href="/" active={currentUrlArray[3] === '' || currentUrlArray[3] === 'home' ? true : false} >
                            Accueil
                        </Nav.Link>
                        <Nav.Link href="/register" active={currentUrlArray[3] === 'register' && (currentUrlArray.length === 4 || currentUrlArray.length === 5) ? true : false} >
                            Créer un compte
                        </Nav.Link>
                        <Nav.Link href="/login" active={currentUrlArray[3] === 'login' && (currentUrlArray.length === 4 || currentUrlArray.length === 5) ? true : false}>Se connecter</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
        

    }
}

export default NavKiller;

/**
<li class={currentUrlArray[4] === 'login' && currentUrlArray.length === 5? 'nav-item active' : 'nav-item'} >
                        <a class="nav-link" href="/login">
                            Se connecter
                        </a>
                    </li>
                    
<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style={{ padding: 0 }}>

<a class="navbar-brand" href="/">
    <h1 style={{ paddingLeft: "10px", margin: 0 }}>
        <span style={{ color: 'white', fontSize: '40px', textAlign: 'center' }}>KILL</span>
        <span style={{ color: 'var(--bs-primary)', fontSize: '40px', textAlign: 'center' }}>ER</span>
    </h1>
</a>
<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="true" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
</button>


<div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
        <li class={currentUrlArray[4] === '' || currentUrlArray[4] === 'home'? 'nav-item active' : 'nav-item'}>
            <a class="nav-link" href="/">
                Home
            </a>
        </li>
        <li class={currentUrlArray[4] === 'register' && (currentUrlArray.length === 5 || currentUrlArray.length === 6)? 'nav-item active' : 'nav-item'}>
            <a class="nav-link" href="/register">
                Créer un compte
            </a>
        </li>
        <li class= 'nav-item disabled'>
            <a class="nav-link disabled" href="/login">
                Se connecter
            </a>
        </li>
    </ul>
</div>
</nav>
 */