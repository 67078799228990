import { useLocation} from 'react-router-dom';
import FinalRegistration from './FinalRegistration';
import FirstRegistration from './FirstRegistration';
import { useEffect, useState } from 'react';

function Register() {
    const [token,setToken] = useState(undefined);
    const location = useLocation()

    useEffect(()=>{
        setToken(location.pathname.split('/register/')[1])
    },[location]);

    return (
        <>
            {
                token ?
                <FinalRegistration token={token}/>
                : <FirstRegistration/>
                

            }
        </>
    )
}

export default Register;