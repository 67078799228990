import { useEffect } from 'react';
import '../../App.css';
import { useState } from 'react';
import { useNavigate } from 'react-router';



const EMAIL_ERROR = "Adresse e-mail invalide"
const EMPTY_FIELD_ERROR = "Champ obligatoire"

function FirstRegistration({ token }) {
    const [formData, setFormData] = useState({});
    const [textEmailError, setTextEmailError] = useState(undefined);
    const [canSend, allowSend] = useState(false);
    
    const [emailError, setEmailError] = useState(false);
    const nav = useNavigate();

    const onFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };



    const isEmail = (value) => {
        let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(value);
    }

    useEffect(() => {
        if (!formData.email) {
            return
        }

        if (formData.email !== undefined && (formData.email.length === 0 || !isEmail(formData.email))) {
            if (!formData.firstname || !formData.lastname) {
                setEmailError(false);
            }
            else if (formData.email.length === 0) {
                setTextEmailError(EMPTY_FIELD_ERROR);
            } else {
                setTextEmailError(EMAIL_ERROR)
            }
            setEmailError(true);
        } else {
            setEmailError(false);
        }

    }, [formData])
    useEffect(() => {
        if (emailError) {
            allowSend(false);
        } else {
            allowSend(true);
        }
    }, [emailError])

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            let res = await fetch("https://api.killer-app.fr/recover/",
                {
                    method: "POST",
                    body: JSON.stringify({
                        email: formData.email,
                    }),
                    headers: {
                        "content-type": "application/json",
                        "origin": "http://localhost:3000",
                        "Access-Control-Request-Method": "POST",
                        "connection": "keep-alive",
                        "accept": "*/*"
                    }
                });
            console.log(res);
            // let resJson = await res.json();
            if (res.status === 204) {
                alert("Recuperation lancée")
                nav('/');
            }
            else {
                const error = await res.json();
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <div className="container-fluid" style={{ minHeight: '100vh', padding: '0', display: 'flex', flexDirection: 'column', backgroundColor: "#3C3A38" }}>
                <div className="row" style={{ margin: 0 }}>
                    <div class="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}>
                        <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">
                            <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>KILL</h1><h1 style={{ color: 'var(--bs-primary)', fontSize: '69px', textAlign: 'center' }}>ER</h1>

                        </div>
                    </div>
                    <div className="row" >
                        <div class="content">

                            <div class="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}><div className="container mt-4">
                                <form onSubmit={onSubmit} style={{ maxWidth: "600px", margin: "auto" }}>
                                    <div className="form-group" style={{ color: `${emailError ? 'red' : 'white'}` }}>
                                        <label>
                                            E-mail
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            style={{ color: `${emailError ? 'red' : 'black'}` }}
                                            value={formData.email}
                                            onChange={onFormChange}
                                            className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">
                                            {emailError ? textEmailError : ''}
                                        </div>
                                    </div>
                                    
                                    <div className="mt-4" style={{ textAlign: 'center' }}>
                                        <button
                                            style={{ textAlign: 'center' }}
                                            type="submit"
                                            className={`btn ${canSend ? 'btn-success' : 'btn-danger'}`}
                                            disabled={emailError
                                            }>
                                            Recuperer mon compte
                                        </button>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            <div className="mt-4" style={{ textAlign: 'center' }}>
                <a href="/register" style={{ color: 'white' }}>Pas encore de compte ? <span style={{ color: 'var(--bs-primary)'  }}>Cliquez ici !</span></a>
            </div>
            </div>
        </>
    )
}

export default FirstRegistration;