

function Footer(){

    return (
      <>
        <footer className={`footer text-center bg-dark mb-0 `} style={{width : '100%' ,marginBottom: '0',marginTop: 'auto'}} >
          <div className="container text-white py-4 py-lg-5">

            <p className="mb-0" id="copyright">
              Copyright © 2023 Killer-app
            </p>
          </div>
        </footer>
      </>
    );
  }

export default Footer;

/**
 *             <ul class="list-inline">
              <li class="list-inline-item me-4">
                <a class="link-d disabled" href="/">
                  Web design
                </a>
              </li>
              <li class="list-inline-item me-4">
                <a class="link-d disabled" href="/">
                  Development
                </a>
              </li>
              <li class="list-inline-item">
                <a class="link-d disabled" href="/">
                  Hosting
                </a>
              </li>
            </ul>
 */