import { useEffect } from 'react';
import '../../App.css';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { browserName, browserVersion, osName } from 'react-device-detect';



const EMAIL_ERROR = "Adresse e-mail invalide"
const EMPTY_FIELD_ERROR = "Champ obligatoire"
const FIRSTNAME_ERROR = "Caractère interdit"
const LASTNAME_ERROR = "Caractère interdit"
const DIFF_PASSWORD_ERROR = "Les mots de passes ne correspondent pas"
const PASSWORD_ERROR = "Le mot de passe est trop court (min : 8 caractères)"

function FinalRegistration({ token }) {

    const [formData, setFormData] = useState({});
    const [passwordError, setPasswordError] = useState(false);
    const [firstnameError, setFirstnameError] = useState(false);
    const [lastnameError, setLastnameError] = useState(false);
    const [textPasswordError, setTextPasswordError] = useState(undefined);
    const [textEmailError, setTextEmailError] = useState(undefined);
    const [location, setLocation] = useState(null);
    const [canSend, allowSend] = useState(false);


    useEffect(() => {
        if (navigator.geolocation) {
            console.log(navigator.geolocation.getCurrentPosition(success, error));
        } else {
            console.log("Geolocation not supported on this browser");
        }
    }, [])


    const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
            .then(response => response.json())
            .then(data => {
                console.log(`${data.city}, ${data.countryName}`);
                setLocation(`${data.city}, ${data.countryName}`)
            })
            .catch(error => console.log(error));
    }
    const error = () => {
        console.log("Error...")
    }

    const [emailError, setEmailError] = useState(false);
    const nav = useNavigate();

    const onFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const isEmail = (value) => {
        let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(value);
    }
    const isValidName = (value) => {
        let regName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
        return regName.test(value);
    }


    useEffect(() => {
        if (!formData.verify_password || !formData.password || !formData.email || !formData.firstname || !formData.lastname) {
            return
        }

        if (((!formData.password && formData.verify_password?.length === 0) || formData.password?.length < 8) || formData.password !== formData.verify_password) {
            if (formData.password.length < 8) {
                setTextPasswordError(PASSWORD_ERROR);
            } else {
                setTextPasswordError(DIFF_PASSWORD_ERROR);
            }
            setPasswordError(true);
        } else {
            setTextPasswordError(undefined);
            setPasswordError(false);
        }
        if (formData.email !== undefined && (formData.email.length === 0 || !isEmail(formData.email))) {
            if (!formData.firstname || !formData.lastname) {
                setEmailError(false);
            }
            else if (formData.email.length === 0) {
                setTextEmailError(EMPTY_FIELD_ERROR);
            } else {
                setTextEmailError(EMAIL_ERROR)
            }
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        if (!isValidName(formData.lastname)) {
            setLastnameError(true);
        } else {
            setLastnameError(false);
        }
        if (!isValidName(formData.firstname)) {
            setFirstnameError(true);
        } else {
            setFirstnameError(false);
        }


    }, [formData])
    useEffect(() => {
        if (emailError
            || passwordError
            || firstnameError
            || lastnameError) {
            allowSend(false);
        } else {
            allowSend(true);
        }
    }, [emailError, firstnameError, lastnameError, passwordError])

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            let res = await fetch("https://api.killer-app.fr/register/" + token,
                {
                    method: "POST",
                    body: JSON.stringify({
                        email: formData.email,
                        firstname: formData.firstname,
                        lastname: formData.lastname,
                        password: formData.password,
                        location: location,
                        os: `${osName} (${browserName}, v${browserVersion})`
                    }),
                    headers: {
                        "content-type": "application/json",
                        "origin": "http://localhost:3000",
                        "Access-Control-Request-Method": "POST",
                        "connection": "keep-alive",
                        "accept": "*/*"
                    }
                });

            // let resJson = await res.json();
            if (res.status === 200) {
                alert("inscription réussie")
                nav('/');
            }else{
                alert("Erreur lors de l'inscription")
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <div className="container-fluid" style={{ minHeight: '100vh', padding: '0', display: 'flex', flexDirection: 'column', backgroundColor: "#3C3A38" }}>
                <div className="row" style={{ margin: 0 }}>
                    <div class="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}>
                        <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">
                            <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>KILL</h1><h1 style={{ color: 'var(--bs-primary)', fontSize: '69px', textAlign: 'center' }}>ER</h1>

                        </div>
                    </div>
                    <div className="row" >
                        <div class="content">

                            <div class="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}><div className="container mt-4">
                                <form onSubmit={onSubmit} style={{ maxWidth: "600px", margin: "auto" }}>
                                    <div className="form-group" style={{ color: `${emailError ? 'red' : 'white'}` }}>
                                        <label>
                                            Confirmez votre E-mail
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            style={{ color: `${emailError ? 'red' : 'black'}` }}
                                            value={formData.email}
                                            onChange={onFormChange}
                                            className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">
                                            {emailError ? textEmailError : ''}
                                        </div>
                                    </div>
                                    <div className="form-group" style={{ color: `${firstnameError ? 'red' : 'white'}` }}>
                                        <label>
                                            Prénom
                                        </label>
                                        <input
                                            type="text"
                                            name="firstname"
                                            style={{ color: `${firstnameError ? 'red' : 'black'}` }}
                                            value={formData.firstname}
                                            onChange={onFormChange}
                                            className={`form-control ${firstnameError ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">
                                            {firstnameError ? FIRSTNAME_ERROR : ''}
                                        </div>
                                    </div>
                                    <div className="form-group" style={{ color: `${lastnameError ? 'red' : 'white'}` }}>
                                        <label>
                                            Nom
                                        </label>
                                        <input
                                            type="text"
                                            name="lastname"
                                            style={{ color: `${lastnameError ? 'red' : 'black'}` }}
                                            value={formData.lastname}
                                            onChange={onFormChange}
                                            className={`form-control ${lastnameError ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">
                                            {emailError ? LASTNAME_ERROR : ''}
                                        </div>
                                    </div>
                                    <div className="form-group" style={{ color: `${passwordError ? 'red' : 'white'}` }}>
                                        <label>
                                            Mot de passe
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            style={{ color: `${passwordError ? 'red' : 'black'}` }}
                                            value={formData.password}
                                            onChange={onFormChange}
                                            className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">
                                            {passwordError ? textPasswordError : ''}
                                        </div>
                                    </div>
                                    <div className="form-group" style={{ color: `${passwordError ? 'red' : 'white'}` }}>
                                        <label>
                                            Répétez le mot de passe
                                        </label>
                                        <input
                                            type="password"
                                            name="verify_password"
                                            style={{ color: `${passwordError ? 'red' : 'black'}` }}
                                            value={formData.verify_password}
                                            onChange={onFormChange}
                                            className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                        />
                                    </div>

                                    <div className="mt-4" style={{ textAlign: 'center' }}>
                                        <button
                                            style={{ textAlign: 'center' }}
                                            type="submit"
                                            className={`btn ${canSend ? 'btn-success' : 'btn-danger'}`}
                                            disabled={emailError
                                                || passwordError
                                                || firstnameError
                                                || lastnameError
                                                || !formData.email
                                                || !formData.password
                                                || !formData.firstname
                                                || !formData.lastname
                                            }>
                                            S'inscrire
                                        </button>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            <div className="mt-4" style={{ textAlign: 'center' }}>
                <a href="/login" style={{ color: 'white' }}>Vous avez déjà un compte ? <span style={{ color: 'var(--bs-primary)'  }}>Cliquez ici !</span></a>
            </div>
            </div>
        </>
    )
}

export default FinalRegistration;