import {logoutApi } from '../Services/serviceApi';
import React from 'react';
import { useNavigate } from 'react-router';


function Logout() {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');

    const handleLogout = async () => {

        if (!token && !email) {
            return;
        }
        await logoutApi();
        localStorage.clear();
    }

    handleLogout();

    const navigate = useNavigate();
    navigate('/');

    return (
        <>
                <div className="container-fluid" style={{ minHeight: '100vh', padding: '0', display: 'flex', flexDirection: 'column', backgroundColor: "#3C3A38" }}>

                    <div className="row" style={{ margin: 0 }}>
                        <div className="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}>
                            <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">
                                <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>KILL</h1><h1 style={{ color: 'var(--bs-primary)', fontSize: '69px', textAlign: 'center' }}>ER</h1>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ margin: 0 }}>
                        <div className="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}>
                            <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">
                                
                                <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>Vous avez été deconnecté </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default Logout;