//import { useEffect, useState } from 'react';
import './Home.css';
import athena from './assets/athena.png';



function Home() {
  /**
    const [releasename, setReleasename] = useState(0);

    async function getVersion() {
        try {
            let res = await fetch("https://api.killer-app.fr/version",
                {
                    method: "GET",
                });
            let data = await res.json();
            console.log(data);
            return data.releaseName;
        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {
        getVersion().then((data) => {
            setReleasename(data);
        });
    }, []);
*/
  const containerStyle = {
    background: "url(" + athena + ") left bottom/contain no-repeat, #000000",
    minHeight: '100vh',
  };

  return (
    <div>
      <div className="row" style={{ margin: 0 }}>
        <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center" style={containerStyle}>
          <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>KILL</h1><h1 style={{ color: 'var(--bs-primary)', fontSize: '69px', textAlign: 'center' }}>ER</h1>
        </div>
      </div>
      
    </div>
  );
};


export default Home;

/**
 <div className="row" style={{ margin: 0, background: '#3C3A38' }}>
        <div className="col">
          <div className="container py-4 py-xl-5">
            <div className="row mb-5">
              <div className="col-md-8 col-xl-6 text-center mx-auto" style={{ color: 'rgb(255,255,255)' }}>
                <h2>LES OFFRES</h2>
                <p className="w-lg-50">Une aventure social ! Le MMFRPG (Jeu de rôle grandeur nature massivement multijoueur)</p>
              </div>
            </div>
            <div className="row gy-4 gx-md-0 gy-md-0 row-cols-1 row-cols-md-2 row-cols-xl-3 d-md-flex d-xl-flex align-items-md-center">
              <div className="col offset-xl-2" style={{ marginRight: '-10px'}}>
                <div className="card bg-body-tertiary border-0" style={{ borderRadius: '30px'}}>
                  <div className="card-body p-4">
                    <div className="d-flex justify-content-between" style={{color:'black'}}>
                      <div>
                        <h3 className="fw-bold mb-0">Offrande d'un mortel</h3>
                        <p>L'application</p>
                        <h4 className="display-6 fw-bold" style={{ margin: 0 }}>1€50</h4>
                        <p>Par joueur</p>
                      </div>
                    </div>
                    <div style={{color:'black'}}>
                      <ul className="list-unstyled">
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>Une partie</span></li>
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>Un accès pour tous a l'application</span></li>
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>Des cartes à l'effigie de killer-app</span></li>
                      </ul>
                    </div>
                    <a className="btn btn-primary d-block w-100 disabled" role="button" href="/" style={{color:'#FFFFFF'}}>Bientôt disponible</a>

                  </div>
                </div>
              </div>
              <div className="col" style={{ zIndex : 10 }} >
                <div className="card text-white bg-primary border-0" style={{ borderRadius: '30px',boxShadow: '0px 20px 30px 0px rgba(0,0,0,0.40)', zIndex : 10 }}>
       
                  <div className="card-body p-4" >
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="fw-bold text-white mb-0">Offrande de Zagreus</h3>
                        <p>L'application et le bonheur des joueurs</p>
                        <h4 className="display-6 fw-bold text-white" style={{ margin: 0 }}>3€</h4>
                        <p>Par joueur</p>
                      </div>
                      <div><span class="badge rounded-pill bg-primary text-uppercase bg-white-300">TOUT COMPRIS</span></div>
                    </div>
                    <div>
                      <ul className="list-unstyled">
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>Offrande d'un mortel</span></li>
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>Personnalisation des cartes</span></li>
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>Événement supplémentaire</span></li>
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>
                          Liste des armes personnalisées</span></li>
                        <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                          </svg>
                        </span><span>Débloque des bonus supplémentaires pour les joueurs</span></li>
                      </ul>
                    </div>
                    <a className="btn btn-primary d-block w-100 bg-white-300 disabled" role="button" href="/" style={{color:'#FFFFFF'}}>Bientôt disponible</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ color: 'rgb(255,255,255)', marginTop : "20px" }}>
                <p className="text-center w-lg-50" style={{ color: '#ffffff' }}>                  
                  Offres pour la version {releasename} du killer.
                  <br />
                  Contactez nous pour une expérience plus personnalisée.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
 */