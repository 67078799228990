import { Routes, Route } from 'react-router-dom';
//import './App.css';
import Home from "./Pages/Home/Home";
import Register from './Pages/Registration/Register';
import Admin from './Pages/Admin';
import Customer from './Pages/Customer';
import Game from './Pages/Game/Game';
import Footer from './components/footer';
import NavKiller from './components/navbar';
import Login from './Pages/Login';
import RecoverPassword from './Pages/Registration/RecoverPassword';
import ForgottenPassword from './Pages/Registration/ForgottenPassword.js';
import Profil from './Pages/Profil.js';
import Logout from './Pages/Logout';

import 'bootstrap/dist/css/bootstrap.min.css'


function App() {
  document.title = 'KILLER';
  return (
    <div>
      <NavKiller/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/register/*" element={<Register/>} />
        <Route path="/login/*" element={<Login/>} />
        <Route path="/admin" element={<Admin/>} />
        <Route path="/game" element={<Game/>} />
        <Route path="/customer" element={<Customer/>} />
        <Route path="/recovery" element={<ForgottenPassword/>} />
        <Route path="/recovery/*" element={<RecoverPassword/>} />
        <Route path="/profil" element={<Profil/>} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="*" element={<Home/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
