import React, { useState, useEffect} from 'react';
import GameList  from '../components/gameList';
import { checkAuthAPI, getUserAPI } from '../Services/serviceApi';
import { Button } from 'react-bootstrap';


function Profil() {

    const [connected, setConnected] = useState(false);
    const [user, setUser] = useState({});
    const [games, setGames] = useState([]);

    useEffect(() => {
        // Appeler la fonction checkAuthAPI lors du montage du composant
        checkAuthAPI().then(status=>{setConnected(status)});

        getUserAPI().then((infos)=>{
            setGames(infos.games)
            setUser(infos.user)
        }).catch(error=>{
            console.error(error)
        });
    },[]);


    if (connected === false) {
        //redirection vers la page de login
        return (
            <>
                <div className="container-fluid" style={{ minHeight: '100vh', padding: '0', display: 'flex', flexDirection: 'column', backgroundColor: "#3C3A38" }}>

                    <div className="row" style={{ margin: 0 }}>
                        <div className="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}>
                            <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">
                                <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>PROF</h1><h1 style={{ color: 'var(--bs-primary)', fontSize: '69px', textAlign: 'center' }}>IL</h1>

                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ margin: 0 }}>
                        <div className="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}>
                            <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">
                                <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>Vous n'êtes pas connecté</h1>
                            </div>
                        </div>
                        <div className="row" style={{ margin: 0 }}>
                            <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">

                                <div className="mt-4" style={{ textAlign: 'center' }}>
                                    <button
                                        style={{ textAlign: 'center' }}
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={() => { window.location.href = "/login" }}
                                    >
                                        Connexion
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    
    return (
        <>
            <div className="container-fluid" style={{ minHeight: '100vh', padding: '0', display: 'flex', flexDirection: 'column', backgroundColor: "#3C3A38" }}>
                <div className="row" style={{ margin: 0 }}>
                    <div class="container d-xl-flex justify-content-xl-center" style={{ textAlign: 'left' }}>
                        <div className="col d-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center align-items-xl-center">
                            <h1 style={{ color: 'white', fontSize: '69px', textAlign: 'center' }}>PROF</h1><h1 style={{ color: 'var(--bs-primary)', fontSize: '69px', textAlign: 'center' }}>IL</h1>

                        </div>
                    </div>

                </div>
                <div className="row" style={{ margin: 0 }}>
                    <div className="col">
                        <h1 style={{ textAlign: 'center' }}>
                            <span style={{ color: '#FFFFFF',textTransform : 'capitalize' }}>{user.firstname}</span> <span style={{ color: 'var(--bs-primary)',textTransform : 'capitalize'}}>{user.lastname}</span>
                        </h1>
                    </div>
                </div>
                <div className="row" style={{ margin: 0 }}>
                    <div className="col">
                        <GameList games={games} />

                    </div>
                </div>
                    <div className="row" style={{ margin: 0 }}>
                    <div className="col" style={{ textAlign: 'center' }}>
                        <Button className="btn btn-primary" disabled type="button" style={{ marginTop: '15px', color: "#FFFFFF" }}>
                            Changer mon mot de passe
                        </Button>
                        <br />
                        <Button className="btn btn-primary" type="button" style={{ marginTop: '15px', marginBottom: '15px', color: "#FFFFFF" }} href={"/logout"}>
                            Se déconnecter
                        </Button>
                    </div>
                </div>

            </div >
        </>
    );
}

export default Profil;