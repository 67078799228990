const checkAuthAPI = async () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    try {
        // Appel à l'API
        console.log(email)
        console.log(token)
        const response = await fetch("https://api.killer-app.fr/check-auth",
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "origin": "http://localhost:3000",
                    "Access-Control-Request-Method": "POST",
                    "connection": "keep-alive",
                    "accept": "*/*",
                    authorization: "Bearer " + token,
                    email: email
                }
            });
        console.log(response);
        // let resJson = await res.json();
        if (response.status === 200) {
            console.log(response);
            return true

        } else {
            // Gérer les erreurs de l'API
            console.error('Erreur lors de la requête à l\'API');
            //delete token and email from local storage
            localStorage.removeItem('token');
            localStorage.removeItem('email');
            return false
        }
    } catch (error) {
        // Gérer les erreurs liées à la requête
        console.error('Erreur lors de la requête à l\'API', error);
        return false;
    }
};
const getUserAPI = async () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    try {
        // Appel à l'API
        const response = await fetch("https://api.killer-app.fr/me",
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "origin": "http://localhost:3000",
                    "Access-Control-Request-Method": "POST",
                    "connection": "keep-alive",
                    "accept": "*/*",
                    authorization: "Bearer " + token,
                    email: email
                }
            });
        console.log(response);
        let resJson = await response.json();
        if (response.status === 200) {
            const user = resJson.informations.user;
            const games = resJson.informations.games;
            return { user: { firstname: user.first_name, lastname: user.last_name, email: user.mail }, games: games };

        } else {
            // Gérer les erreurs de l'API
            console.error('Erreur lors de la requête à l\'API');
            //delete token and email from local storage
        }
    } catch (error) {
        // Gérer les erreurs liées à la requête
        console.error('Erreur lors de la requête à l\'API', error);
    }
}


const logoutApi = async () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    try {
        // Appel à l'API
        const response = await fetch("https://api.killer-app.fr/logout",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "origin": "http://localhost:3000",
                    "Access-Control-Request-Method": "POST",
                    "connection": "keep-alive",
                    "accept": "*/*",
                    authorization: "Bearer " + token,
                    email: email
                }
            });
        console.log(response);
        if (response.status === 200) {
            //delete token and email from local storage
            localStorage.removeItem('token');
            localStorage.removeItem('email');
            
            return true;
        } else {
            // Gérer les erreurs de l'API
            console.error('Erreur lors de la requête à l\'API');
            return false;
        }
    } catch (error) {
        // Gérer les erreurs liées à la requête
        console.error('Erreur lors de la requête à l\'API', error);
        return false;
    }

}
export { checkAuthAPI, getUserAPI, logoutApi };